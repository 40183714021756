import axios from 'axios';
import { useState, useRef } from 'react';
import './App.css';

function App() {
  const [currentRequestURL, setRequestURL] = useState('https://demo.aipal.chat/generate_response');

  const [isLoading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [personaDescription, setPersonaDescription] = useState('Your name is Jane\nYou are a 22 years old fashion blogger');
  const [instructions, setInstructions] = useState('You should speak flirty and ask for a date in 3rd message. You should avoid conflicts');

  const [isMobileOverlay, setMobileOverlay] = useState(false);
  const [selectedTokensAmount, setSelectedTokensAmount] = useState(35);
  const [selectedTemperature, setSelectedTemperature] = useState(1.2);
  const [selectedTopP, setSelectedTopP] = useState(0.9);
  const [selectedPresencePenalty, setSelectedPresencePenalty] = useState(1.2);
  const [selectedFrequencyPenalty, setSelectedFrequencyPenalty] = useState(2);
  const [selectedDoLongGeneration, setSelectedDoLongGeneration] = useState('false');
  const [selectedMinTokens, setSelectedMinTokens] = useState(100);
  const [customOpen, setCustomOpen] = useState(false);
  const [customURL, setCustomURL] = useState('');
  const [firstMessage, setFirstMessage] = useState('');

  const makeFirstMessage = (text) => {
    return {
      speaker: 'bot',
      phrase: text,
      model: 'none',
    };
  };

  const truthSource = useRef([
    // {
    //   speaker: 'bot',
    //   type: 'input',
    //   phrase: "Hi, how it's going",
    //   model: 'none',
    // },
  ]);

  const [chatHistory, setChatHistory] = useState([
    // {
    //   speaker: 'bot',
    //   phrase: "Hi, how it's going",
    //   model: 'none',
    // },
  ]);

  const dropHistory = () => {
    setChatHistory([]);
    truthSource.current = [];
  };

  const createMessage = (from, text, model) => {
    return { speaker: from, phrase: text, model: model };
  };

  const handleUserMessage = (event) => {
    event.preventDefault();
    const userMessage = inputValue.trim();
    if (userMessage.length === 0) return;
    splitPersonaDescription();
    setInputValue('');
    insertMessage(createMessage('user', userMessage));
    makeRequest();
  };

  const insertMessage = (message) => {
    truthSource.current = [...truthSource.current, message];
    setChatHistory([...truthSource.current]);
    scrollChat();
  };

  const splitPersonaDescription = () => {
    let personaDescriptionArray = personaDescription.split(/\n/);
    for (let i = 0; i < personaDescriptionArray.length; i++) {
      let currentTarget = personaDescriptionArray[i];
      console.log(currentTarget[currentTarget.length - 1]);
      if (currentTarget[currentTarget.length - 1] === '.') {
        let wordArray = currentTarget.split('');
        wordArray[currentTarget.length - 1] = '';
        let final = wordArray.join('');
        personaDescriptionArray[i] = final;
      }
    }
    return personaDescriptionArray;
  };

  const splitInstructions = () => {
    let instructionsArray = instructions.split(/\n/);
    for (let i = 0; i < instructionsArray.length; i++) {
      let currentTarget = instructionsArray[i];
      console.log(currentTarget[currentTarget.length - 1]);
      if (currentTarget[currentTarget.length - 1] === '.') {
        let wordArray = currentTarget.split('');
        wordArray[currentTarget.length - 1] = '';
        let final = wordArray.join('');
        instructionsArray[i] = final;
      }
    }
    return instructionsArray;
  };

  const getRequestData = () => {
    let tokens = selectedTokensAmount;
    if (tokens < 2) {
      setSelectedTokensAmount(2);
      tokens = 2;
    }
    if (tokens > 4000) {
      setSelectedTokensAmount(4000);
      tokens = 4000;
    }

    let frequencyPenalty = selectedFrequencyPenalty;
    if (frequencyPenalty < 0.0) {
      setSelectedFrequencyPenalty(0.0);
      frequencyPenalty = 0.0;
    }
    if (frequencyPenalty > 10.0) {
      setSelectedFrequencyPenalty(10.0);
      frequencyPenalty = 10.0;
    }
    let doLongGeneration = selectedDoLongGeneration;
    let minTokens = selectedMinTokens;
    if (minTokens < 2) {
      setSelectedMinTokens(2);
      minTokens = 2;
    }
    if (minTokens > 1500) {
      setSelectedMinTokens(1500);
      minTokens = 1500;
    }
    let temperature = selectedTemperature;
    let topP = selectedTopP;
    let presencePenalty = selectedPresencePenalty;

    let object = {
      context: [makeFirstMessage(firstMessage), ...truthSource.current],
      persona_description: splitPersonaDescription(),
      inference_params: {
        max_tokens: tokens,
        frequency_penalty: frequencyPenalty,
        temperature: temperature,
        top_p: topP,
        presence_penalty: presencePenalty,
      },
    };
    let additional_params = { use_postprocess: true };
    if (doLongGeneration === 'true') {
      additional_params['do_long_generation'] = true;
      additional_params['min_tokens'] = minTokens;
    }

    if (instructions.length !== 0) {
      additional_params.instructions = splitInstructions();
    }

    object.additional_params = additional_params;
    console.log(object);
    return object;
  };

  const makeRequest = async () => {
    setLoading(true);
    const data = getRequestData();
    const request = await axios.post(currentRequestURL, data);
    console.log(request);
    const { response, model_id } = request.data;
    const model = model_id;
    insertMessage(createMessage('bot', response, model));
    setLoading(false);
    setTimeout(() => {
      document.getElementById('input').focus();
    }, 50);
  };

  const scrollChat = () => {
    const chat = document.getElementById('chat');
    console.log(chat.scrollHeight);
    chat.scrollTop = chat.scrollHeight;
  };

  const handleTokens = (event) => {
    const a = parseInt(event.target.value);
    setSelectedTokensAmount(a);
  };

  const handleTemperature = (event) => {
    const a = parseFloat(event.target.value);
    setSelectedTemperature(a);
  };

  const handleTopP = (event) => {
    const a = parseFloat(event.target.value);
    setSelectedTopP(a);
  };

  const handlePresencePenalty = (event) => {
    const a = parseFloat(event.target.value);
    setSelectedPresencePenalty(a);
  };

  const handleFrequencyPenalty = (event) => {
    const a = parseFloat(event.target.value);
    setSelectedFrequencyPenalty(a);
  };

  const handleDoLongGeneration = (event) => {
    let a = event.target.value.toLowerCase();
    setSelectedDoLongGeneration(a);
  };

  const handleMinTokens = (event) => {
    const a = parseInt(event.target.value);
    setSelectedMinTokens(a);
  };

  const handleRequestURLChange = (event) => {
    const { value } = event.target;
    if (value !== 'custom') {
      setRequestURL(value);
    } else {
      setCustomOpen(true);
    }
  };

  return (
    <>
      {customOpen && (
        <div className="overlay">
          <div className="overlay-content">
            <span>Custom URL</span>
            <input type="text" value={customURL} onChange={(e) => setCustomURL(e.target.value)}></input>
            <button
              onClick={(event) => {
                event.preventDefault();
                if (customURL.length === 0) {
                  return;
                }
                setRequestURL(customURL);
                setCustomURL('');
                setCustomOpen(false);
              }}
            >
              Apply
            </button>
          </div>
        </div>
      )}
      <div className="container">
        <button className="settings-btn" onClick={() => setMobileOverlay(true)}>
          settings
        </button>
        <div className="areas-container desktop">
          <span>
            <i>{currentRequestURL}</i>
          </span>
          <div className="request-url-btns">
            <select onChange={handleRequestURLChange} className="request-select">
              <option value={'https://demo.aipal.chat/generate_response'}>generate_response</option>
              <option value={'https://gptmodel.aipal.me/chatgpt_generate_response'}>chatgpt_response</option>
              <option value={'https://demo.aipal.chat/ivanyevtushenko_generate_response'}>ivanyevtushenko_generate_response</option>
              <option value={'https://demo.aipal.chat/vfilipovich_generate_response'}>vfilipovich_generate_response</option>
              <option value={'https://gptmodel.aipal.me/eplotnikov_cosmo_generate_response'}>eplotnikov_cosmo_generate_response</option>
              <option value={'https://gptmodel.aipal.me/prod_generate_response'}>prod_generate_response</option>
              <option value={'custom'}>custom</option>
            </select>
          </div>
          <div className="input-row">
            <span>Tokens Amount:</span>
            <input type="number" value={selectedTokensAmount} onChange={handleTokens}></input>
          </div>
          <div className="input-row">
            <span>Temperature:</span>
            <input type="number" value={selectedTemperature} onChange={handleTemperature}></input>
          </div>
          <div className="input-row">
            <span>Top-P:</span>
            <input type="number" value={selectedTopP} onChange={handleTopP}></input>
          </div>
          <div className="input-row">
            <span>Frequency Penalty:</span>
            <input type="number" value={selectedFrequencyPenalty} onChange={handleFrequencyPenalty}></input>
          </div>
          <div className="input-row">
            <span>Presence Penalty:</span>
            <input type="number" value={selectedPresencePenalty} onChange={handlePresencePenalty}></input>
          </div>
          <div className="input-row">
            <span>Do Long Generation:</span>
            <input type="text" value={selectedDoLongGeneration} onChange={handleDoLongGeneration}></input>
          </div>
          <div className="input-row">
            <span>Min Tokens Amount (if DoLongGeneration is true):</span>
            <input type="number" value={selectedMinTokens} onChange={handleMinTokens}></input>
          </div>
          <span>Persona Description</span>
          <textarea
            placeholder="Type multi-line description of your character"
            value={personaDescription}
            onChange={(e) => setPersonaDescription(e.target.value)}
          />
          <span>Instructions</span>
          <textarea placeholder="Type your GPT Instructions" value={instructions} onChange={(e) => setInstructions(e.target.value)} />
        </div>
        <div id="chat" className="chat-container">
          <button className="delete" onClick={dropHistory}>
            Drop chat history
          </button>
          <div className="input-row">
            <input type="text" placeholder="First message text" value={firstMessage} onChange={(e) => setFirstMessage(e.target.value)}></input>
          </div>
          {chatHistory.map((message) => {
            return (
              <div className={message.speaker === 'bot' ? 'message bot' : 'message user'}>
                <span className="model-id">{message.model}</span>
                {message.phrase}
              </div>
            );
          })}
        </div>
        <form onSubmit={handleUserMessage}>
          <input
            disabled={isLoading}
            id="input"
            autoFocus={true}
            placeholder="Write a Message"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="fixed-input"
          ></input>
          <button style={{ opacity: inputValue.length === 0 ? 0 : 1 }} className="fixed-btn">
            Send
          </button>
        </form>
      </div>
    </>
  );
}

export default App;
